import React from 'react';
import { Box, Button, Card, CardMedia, Grid, Typography } from '@mui/material';
import { capitalizeFirstLetter } from 'shared/helper';

import {
  CategoryName,
  useHelperMainPage,
} from 'models/Main/hooks/useHelperMainPage';
import {
  cardStyle,
  mainGrid,
  categoryName,
  gridCategories,
  categoriesNames,
  boxContainer,
  numberOrange,
  numberGreen,
  sellBtn,
  findBtn,
  gridImages,
  images,
  smallScreenGrid,
} from '../styles/mainPageCardStyles';
import { Category } from 'services/interfaces/Category';
import { Drawer } from 'shared/ui/Drawer';
import theme from 'shared/theme';
import { categoryAdvertForm } from 'shared/helper/formAdvert';
import SignModal from 'features/SignModal';
import { useRouter } from 'next/router';
import { declination } from 'shared/helper/declination';
import { Company } from 'services/interfaces/Company';
import { buttonNameCategory } from 'shared/helper/buttonNameCategory';
import { categoryTender } from 'models/TenderMarketplace/styles/tenderCardStyles';

const MainPageCard = ({
  category,
  company,
}: {
  category: Category;
  company: Company[] | [];
}) => {
  const {
    t,
    categoryImages,
    session,
    measureUnit,
    machineRoom,
    carryingCapacity,
    liftingSpeed,
    onOpenDrawer,
    openDrawer,
    userCompany,
    selectedCategory,
    getCategoryTitle,
  } = useHelperMainPage();

  return (
    <Grid
      item
      lg={6}
      md={6}
      sm={12}
      xs={12}
      key={category.id}
      sx={{ pl: '6px !important' }}
    >
      <Card sx={cardStyle}>
        <Grid item lg={8} md={7} sm={8} xs={12} sx={mainGrid}>
          <Box>
            <Typography
              component={'a'}
              variant="h4"
              fontFamily={'Rubik'}
              fontWeight={500}
              href={`/companies?category=${category.alias}`}
              sx={categoryName}
            >
              {capitalizeFirstLetter(category.name)}
            </Typography>
            <Box>
              <Grid item xs={11} mb={1} sx={gridCategories}>
                {category?.categories?.slice(0, 5).map((item: any) => (
                  <Typography
                    key={item.id}
                    component="a"
                    href={`/companies?category=${category.alias}`}
                    sx={categoriesNames}
                  >
                    {capitalizeFirstLetter(item.name)}
                  </Typography>
                ))}
              </Grid>
            </Box>
          </Box>

          <Grid container>
            <Grid item container sx={smallScreenGrid} xs={12} mb={1}>
              {/* <Box sx={boxContainer}>
                <Button size={'small'} variant="outlined" sx={numberGreen}>
                  47 <br /> {declination(47, 'Заявка', 'Заявки', 'Заявок')}
                </Button>
              </Box> */}
              <Box sx={boxContainer}>
                <Button
                  href={`/companies?category=${category.alias}`}
                  size={'small'}
                  variant="outlined"
                  sx={numberOrange}
                >
                  {category.totalOrganization || 0} <br />
                  {declination(
                    category.totalOrganization || 0,
                    'Компания',
                    'Компании',
                    'Компаний'
                  )}
                </Button>
              </Box>
              <Box sx={boxContainer}>
                <Button
                  href={`/marketplace?category=${category.alias}`}
                  size={'small'}
                  variant="outlined"
                  sx={numberOrange}
                >
                  {category.totalAdvert} <br />
                  {declination(
                    category.totalAdvert,
                    'Объявление',
                    'Объявления',
                    'Объявлений'
                  )}
                </Button>
              </Box>
            </Grid>
            <Grid item container spacing={1} xs={12} sx={smallScreenGrid}>
              {session ? null : (
                <Grid item>
                  <SignModal
                    title={`${getCategoryTitle(
                      category.alias as CategoryName,
                      'buttonNoSignIn'
                    )}`}
                    onOpenDrawer={() => onOpenDrawer(category.alias)}
                    sx={sellBtn}
                    variant={'contained'}
                  />
                </Grid>
              )}
              {session && (
                <Grid item>
                  {session && (userCompany.length > 0 || company.length > 0) ? (
                    <Drawer
                      flexBasis={'100%'}
                      display={
                        session &&
                        selectedCategory === category.alias &&
                        (userCompany.length > 0 || company.length > 0
                          ? 'block'
                          : 'none')
                      }
                      margin={2}
                      padding={'3px 10px'}
                      mt={0.5}
                      fontSize={'14px'}
                      size={'small'}
                      fontWeight={500}
                      minWidth={'200px'}
                      title={`${t('add')} ${buttonNameCategory(
                        category.alias
                      )}`}
                      btnTitle={`${getCategoryTitle(
                        category.alias as CategoryName,
                        'buttonDrawer'
                      )}`}
                      background={theme.palette.secondary.main}
                      color={theme.palette.grey[900]}
                      openDrawer={openDrawer}
                    >
                      {categoryAdvertForm(
                        category,
                        measureUnit,
                        undefined,
                        undefined,
                        machineRoom,
                        carryingCapacity,
                        liftingSpeed,
                        userCompany
                      )}
                    </Drawer>
                  ) : (
                    <Button
                      href={`/company`}
                      variant="contained"
                      size="small"
                      sx={sellBtn}
                    >
                      {t('add_production')}
                    </Button>
                  )}
                </Grid>
              )}

              <Grid item>
                <Button
                  href={`/companies?category=${category.alias}`}
                  variant="contained"
                  size="small"
                  sx={findBtn}
                >
                  {`${t('find')} ${getCategoryTitle(
                    category.alias as CategoryName,
                    'buttonFind'
                  )}`}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          // display={'none'}
          lg={4}
          md={5}
          sm={5}
          // xs={4}
          sx={gridImages}
        >
          <CardMedia
            component="img"
            alt="Category Image"
            sx={images}
            src={categoryImages[category?.alias]}
          />
        </Grid>
      </Card>
    </Grid>
  );
};
export default MainPageCard;
